import React from 'react';
import logo from './logo.svg';
import './App.css';

// COMPONENTES
import Navigation from './components/Navigation';
import Page1 from './components/page1';
import Page2 from './components/page2';

function App() {
  return (
    <div className="App">
      <Navigation />
    </div>
  );
}

export default App;
