import React, { Component } from 'react';

class page2 extends Component{
    render(){
        return(
            <div class="container-fluid">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Que tipo de desarrollo desea?</h1>
                    </center>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" name="movil" id="movil">
                                <img src="/img/coti/smartphone.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color: 'aliceblue'}}>Desarrollo movil</h3>
                            </button>
                        </center>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" name="web" id="web">
                                <img src="/img/coti/responsive.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color: 'aliceblue'}}>Diseño y desarrollo web</h3>
                            </button>
                        </center>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce">
                                <img src="/img/coti/shopping.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color: 'aliceblue'}}>E-Commerce</h3>
                            </button>
                        </center>
                    </div>
                </div>
            </div>
        )
    }
}

export default page2;