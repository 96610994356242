import React, { Component } from 'react';

class Navigation extends Component{
    constructor(props){
        super(props);
        this.handleClickPage2 = this.handleClickPage2.bind(this);
        this.handleClickPage3Android = this.handleClickPage3Android.bind(this);
        this.handleClickPage3IOS = this.handleClickPage3IOS.bind(this);
        this.handleClickPage3Ambos = this.handleClickPage3Ambos.bind(this);
        this.handleClickPage4InterfazPersonalizada = this.handleClickPage4InterfazPersonalizada.bind(this);
        this.handleClickPage4InterfazReplicada = this.handleClickPage4InterfazReplicada.bind(this);
        this.handleClickPage4InterfazSencilla = this.handleClickPage4InterfazSencilla.bind(this);
        this.handleClickPage5AppGratis = this.handleClickPage5AppGratis.bind(this);
        this.handleClickPage5AppPago = this.handleClickPage5AppPago.bind(this);
        this.handleClickPage5AppCompras = this.handleClickPage5AppCompras.bind(this);
        this.handleClickPage6RedesSociales = this.handleClickPage6RedesSociales.bind(this);
        this.handleClickPage6Email = this.handleClickPage6Email.bind(this);
        this.handleClickPage6No = this.handleClickPage6No.bind(this);
        this.handleClickPage7Si = this.handleClickPage7Si.bind(this);
        this.handleClickPage7No = this.handleClickPage7No.bind(this);
        this.handleClickPage7NoSe = this.handleClickPage7NoSe.bind(this);
        this.handleClickPage8AdminSi = this.handleClickPage8AdminSi.bind(this);
        this.handleClickPage8AdminNo = this.handleClickPage8AdminNo.bind(this);
        this.handleClickPage8AdminNoSe = this.handleClickPage8AdminNoSe.bind(this);
        this.handleClickEndMovil = this.handleClickEndMovil.bind(this);
        //WEB
        this.handleClickPage2web = this.handleClickPage2web.bind(this);
        this.handleClickPage9Sencilla = this.handleClickPage9Sencilla.bind(this);
        this.handleClickPage9Personalizada = this.handleClickPage9Personalizada.bind(this);
        this.handleClickPage9NoSe = this.handleClickPage9NoSe.bind(this);
        this.handleClickPage10Si = this.handleClickPage10Si.bind(this);
        this.handleClickPage10No = this.handleClickPage10No.bind(this);
        this.handleClickPage10NoSe = this.handleClickPage10NoSe.bind(this);
        this.handleClickPage11Si = this.handleClickPage11Si.bind(this);
        this.handleClickPage11No = this.handleClickPage11No.bind(this);
        this.handleClickPage11NoSe = this.handleClickPage11NoSe.bind(this);
        this.handleClickEndWeb = this.handleClickEndWeb.bind(this);

        this.state = {
            pageEndMovil: false,
            pageEndWeb: false,
            page1: true,
            page2: false,
            page3: false,
            page4: false,
            page5: false,
            page6: false,
            page7: false,
            page8: false,
            page9web: false,
            page10web: false,
            page11web: false,
            page12web: false,
            total: 0
        }
    }

    handleClickEndMovil(){
        const activado = this.state.pageEndMovil;
        if(activado){
            this.setState({pageEndMovil:false});
        }else{
            this.setState({page8:false});
            this.setState({pageEndMovil:true});
        }
    }

    handleClickEndWeb(){
        const activado = this.state.pageEndWeb;
        if(activado){
            this.setState({pageEndWeb:false});
        }else{
            this.setState({page12web:false});
            this.setState({pageEndWeb:true});
        }
    }

    handleClickPage1(){
        this.setState({page1:false});
    }

    //MOVIL - ************************************************************************************************************************
    handleClickPage2(){
        const activado = this.state.page2;
        if(activado){
            this.setState({page2:false});
        }else{
            this.setState({page1:false});
            this.setState({page2:true});
        }
    }

    handleClickPage2web(){
        const activado = this.state.page9web;
        if(activado){
            this.setState({page2:false});
        }else{
            this.setState({page1:false});
            this.setState({page9web:true});
        }
    }

    handleClickPage3Android(){
        const activado = this.state.page3;
        var total = this.state.total;
        if(activado){
            this.setState({page3:false});
        }else{
            total = total + 3000;
            this.setState({total:total});
            this.setState({page2:false});
            this.setState({page3:true});
        }
    }

    handleClickPage3IOS(){
        const activado = this.state.page3;
        var total = this.state.total;
        if(activado){
            this.setState({page3:false});
        }else{
            total = total + 5000;
            this.setState({total:total});
            this.setState({page2:false});
            this.setState({page3:true});
        }
    }

    handleClickPage3Ambos(){
        const activado = this.state.page3;
        var total = this.state.total;
        if(activado){
            this.setState({page3:false});
        }else{
            total = total + 10000;
            this.setState({total:total});
            this.setState({page2:false});
            this.setState({page3:true});
        }
    }

    handleClickPage4InterfazSencilla(){
        const activado = this.state.page4;
        var total = this.state.total;
        if(activado){
            this.setState({page4:false});
        }else{
            total = total + 2500;
            this.setState({total:total});
            this.setState({page3:false});
            this.setState({page4:true});
        }
    }

    handleClickPage4InterfazPersonalizada(){
        const activado = this.state.page4;
        var total = this.state.total;
        if(activado){
            this.setState({page4:false});
        }else{
            total = total + 4500;
            this.setState({total:total});
            this.setState({page3:false});
            this.setState({page4:true});
        }
    }

    handleClickPage4InterfazReplicada(){
        const activado = this.state.page4;
        var total = this.state.total;
        if(activado){
            this.setState({page4:false});
        }else{
            total = total + 7500;
            this.setState({total:total});
            this.setState({page3:false});
            this.setState({page4:true});
        }
    }

    handleClickPage5AppGratis(){
        const activado = this.state.page5;
        var total = this.state.total;
        if(activado){
            this.setState({page5:false});
        }else{
            total = total + 1700;
            this.setState({total:total});
            this.setState({page4:false});
            this.setState({page5:true});
        }
    }

    handleClickPage5AppPago(){
        const activado = this.state.page5;
        var total = this.state.total;
        if(activado){
            this.setState({page5:false});
        }else{
            total = total + 3700;
            this.setState({total:total});
            this.setState({page4:false});
            this.setState({page5:true});
        }
    }

    handleClickPage5AppCompras(){
        const activado = this.state.page5;
        var total = this.state.total;
        if(activado){
            this.setState({page5:false});
        }else{
            total = total + 5700;
            this.setState({total:total});
            this.setState({page4:false});
            this.setState({page5:true});
        }
    }

    handleClickPage6RedesSociales(){
        const activado = this.state.page6;
        var total = this.state.total;
        if(activado){
            this.setState({page6:false});
        }else{
            total = total + 3950;
            this.setState({total:total});
            this.setState({page5:false});
            this.setState({page6:true});
        }
    }

    handleClickPage6Email(){
        const activado = this.state.page6;
        var total = this.state.total;
        if(activado){
            this.setState({page6:false});
        }else{
            total = total + 1950;
            this.setState({total:total});
            this.setState({page5:false});
            this.setState({page6:true});
        }
    }

    handleClickPage6No(){
        const activado = this.state.page6;
        var total = this.state.total;
        if(activado){
            this.setState({page6:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page5:false});
            this.setState({page6:true});
        }
    }

    handleClickPage7Si(){
        const activado = this.state.page7;
        var total = this.state.total;
        if(activado){
            this.setState({page7:false});
        }else{
            total = total + 4500;
            this.setState({total:total});
            this.setState({page6:false});
            this.setState({page7:true});
        }
    }

    handleClickPage7No(){
        const activado = this.state.page7;
        var total = this.state.total;
        if(activado){
            this.setState({page7:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page6:false});
            this.setState({page7:true});
        }
    }

    handleClickPage7NoSe(){
        const activado = this.state.page7;
        var total = this.state.total;
        if(activado){
            this.setState({page7:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page6:false});
            this.setState({page7:true});
        }
    }

    handleClickPage8AdminSi(){
        const activado = this.state.page8;
        var total = this.state.total;
        if(activado){
            this.setState({page8:false});
        }else{
            total = total + 10000;
            this.setState({total:total});
            this.setState({page7:false});
            this.setState({page8:true});
        }
    }

    handleClickPage8AdminNo(){
        const activado = this.state.page8;
        var total = this.state.total;
        if(activado){
            this.setState({page8:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page7:false});
            this.setState({page8:true});
        }
    }

    handleClickPage8AdminNoSe(){
        const activado = this.state.page8;
        var total = this.state.total;
        if(activado){
            this.setState({page8:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page7:false});
            this.setState({page8:true});
        }
    }
   //END MOVIL - ************************************************************************************************************************

   //WEB - ************************************************************************************************************************
   handleClickPage9Sencilla(){
        const activado = this.state.page10web;
        var total = this.state.total;
        if(activado){
            this.setState({page10web:false});
        }else{
            total = total + 6000;
            this.setState({total:total});
            this.setState({page9web:false});
            this.setState({page10web:true});
        }
    }

    handleClickPage9Personalizada(){
        const activado = this.state.page10web;
        var total = this.state.total;
        if(activado){
            this.setState({page10web:false});
        }else{
            total = total + 8500;
            this.setState({total:total});
            this.setState({page9web:false});
            this.setState({page10web:true});
        }
    }

    handleClickPage9NoSe(){
        const activado = this.state.page10web;
        var total = this.state.total;
        if(activado){
            this.setState({page10web:false});
        }else{
            total = total + 8500;
            this.setState({total:total});
            this.setState({page9web:false});
            this.setState({page10web:true});
        }
    }

    handleClickPage10Si(){
        const activado = this.state.page11web;
        var total = this.state.total;
        if(activado){
            this.setState({page11web:false});
        }else{
            total = total + 350;
            this.setState({total:total});
            this.setState({page10web:false});
            this.setState({page11web:true});
        }
    }

    handleClickPage10No(){
        const activado = this.state.page11web;
        var total = this.state.total;
        if(activado){
            this.setState({page11web:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page10web:false});
            this.setState({page11web:true});
        }
    }

    handleClickPage10NoSe(){
        const activado = this.state.page11web;
        var total = this.state.total;
        if(activado){
            this.setState({page11web:false});
        }else{
            total = total + 350;
            this.setState({total:total});
            this.setState({page10web:false});
            this.setState({page11web:true});
        }
    }

    handleClickPage11Si(){
        const activado = this.state.page12web;
        var total = this.state.total;
        if(activado){
            this.setState({page12web:false});
        }else{
            total = total + 3500;
            this.setState({total:total});
            this.setState({page11web:false});
            this.setState({page12web:true});
        }
    }

    handleClickPage11No(){
        const activado = this.state.page12web;
        var total = this.state.total;
        if(activado){
            this.setState({page12web:false});
        }else{
            total = total;
            this.setState({total:total});
            this.setState({page11web:false});
            this.setState({page12web:true});
        }
    }

    handleClickPage11NoSe(){
        const activado = this.state.page12web;
        var total = this.state.total;
        if(activado){
            this.setState({page12web:false});
        }else{
            total = total + 3500;
            this.setState({total:total});
            this.setState({page11web:false});
            this.setState({page12web:true});
        }
    }

    //ENDWEB - ************************************************************************************************************************

    render(){
        const page1Act = this.state.page1;
        const page2Act = this.state.page2;
        const page3Act = this.state.page3;
        const page4Act = this.state.page4;
        const page5Act = this.state.page5;
        const page6Act = this.state.page6;
        const page7Act = this.state.page7;
        const page8Act = this.state.page8;
        const page9Act = this.state.page9web;
        const page10Act = this.state.page10web;
        const page11Act = this.state.page11web;
        const page12Act = this.state.page12web;
        const pageEndMovilAct = this.state.pageEndMovil;
        const pageEndWebAct = this.state.pageEndWeb;
        return(
            <div>
           <header class="main_menu">
               <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-12">
                            <nav class="navbar navbar-expand-lg navbar-light">
                                <a class="navbar-brand main_logo" href="index.html"> <img src="img/twosoft logo.png" alt="logo"/> </a>
                                <a class="navbar-brand single_page_logo" href="index.html"> <img src="img/twosoft logo.png" alt="logo"/> </a>
                                <button class="navbar-toggler" type="button" data-toggle="collapse"
                                    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span class="menu_icon"></span>
                                </button>
                                <div class="collapse navbar-collapse main-menu-item" id="navbarSupportedContent">
                                    <ul class="navbar-nav">
                                        <li class="nav-item">
                                            <a class="nav-link" href="index.html">Inicio</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="features.html">Nosotros</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="pricing.html">Precios</a>
                                        </li>
                                        <li class="nav-item">
                                            <a class="nav-link" href="contact.html">Contacto</a>
                                        </li>
                                    </ul>
                                </div>
                                <a href="#" class="d-none d-sm-block btn_1 home_page_btn" style={{color:'aliceblue'}}>${this.state.total} mxn</a>
                            </nav>
                        </div>
                    </div>
               </div>
           </header>

           {page1Act ? 
            <div class="container">
                <div class="row">
                    <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Que tipo de desarrollo desea?</h1>
                    </center>
                    </div>
                </div>
                <div class="row pt-5">
                    <div class="col-sm-6 col-md-4 col-lg-6">
                        <center>
                            <button type="button" class="btn btn-link" name="movil" id="movil" onClick={this.handleClickPage2}>
                                <img src="/img/coti/smartphone.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color: 'aliceblue'}}>Desarrollo movil</h3>
                            </button>
                        </center>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-6">
                        <center>
                            <button type="button" class="btn btn-link" name="web" id="web" onClick={this.handleClickPage2web}>
                                <img src="/img/coti/responsive.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color: 'aliceblue'}}>Diseño y desarrollo web</h3>
                            </button>
                        </center>
                    </div>
                    {/* <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" name="commerce" id="commerce">
                                <img src="/img/coti/shopping.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color: 'aliceblue'}}>E-Commerce</h3>
                            </button>
                        </center>
                    </div> */}
                </div>
            </div>

            :

            <div></div>
            }

            {page2Act ? 
                <div class="container">
                <div class="row">
                    <div class="col">
                        <center>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <br/>
                            <h1 style={{color:'aliceblue'}}>¿Para cual plataforma desea su app?</h1>
                        </center>
                    </div>
                </div>
                <br/>
                <div class="row pt-5">
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickPage3Android}>
                                <img src="/img/coti/android.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color:'aliceblue'}}>Android</h3>
                            </button>
                        </center>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickPage3IOS}>
                                <img src="/img/coti/apple.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color:'aliceblue'}}>IOS</h3>
                            </button>
                        </center>
                    </div>
                    <div class="col-sm-6 col-md-4 col-lg-4">
                        <center>
                            <button type="button" class="btn btn-link" onClick={this.handleClickPage3Ambos}>
                                <img src="/img/coti/android&ios.png" width="35%" alt=""/>
                                <br/>
                                <br/>
                                <h3 style={{color:'aliceblue'}}>Ambas</h3>
                            </button>
                        </center>
                    </div>
                </div>
            </div>
            
            :    

            <div></div>

            }

            {page3Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Que tipo de diseño quiere que tenga su app?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage4InterfazSencilla}>
                            <img src="/img/coti/ui.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Interfaz sencilla</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage4InterfazPersonalizada}>
                            <img src="/img/coti/ui2.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Interfaz personalizada</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage4InterfazReplicada}>
                            <img src="/img/coti/ui3.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Replicada de la web</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>
            
            :

            <div></div>

            }

            {page4Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Como le quiere sacar beneficio a su app?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage5AppGratis}>
                            <img src="/img/coti/appfree.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>App gratuita con publicidad</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage5AppPago}>
                            <img src="/img/coti/apppay.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>App de pago</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage5AppCompras}>
                            <img src="/img/coti/appshop.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Compras dentro de la app</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page5Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Su app nesecita sistema de logueo?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link">
                            <img src="/img/coti/loginsocial.png" width="35%" alt="" onClick={this.handleClickPage6RedesSociales}/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Si, con redes sociales e email</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage6Email}>
                            <img src="/img/coti/loginemail.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Si, con email</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage6No}>
                            <img src="/img/coti/loginno.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page6Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Su app necesita estar integrada al sitio web?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage7Si}>
                            <img src="/img/coti/pagesix.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Si</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage7No}>
                            <img src="/img/coti/page62.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage7NoSe}>
                            <img src="/img/coti/page63.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No lo sé</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }
            
            {page7Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Su app necesita un panel administrativo?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage8AdminSi}>
                            <img src="/img/coti/adminsi.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Si</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage8AdminNo}>
                            <img src="/img/coti/adminno.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage8AdminNoSe}>
                            <img src="/img/coti/page63.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No lo sé</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page8Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Cuál es el estado de su proyecto?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndMovil}>
                            <img src="/img/coti/idea.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Es solo una idea</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndMovil}>
                            <img src="/img/coti/sketch.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Ya se tiene el boceto</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndMovil}>
                            <img src="/img/coti/code.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>La app esta en desarrollo</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link">
                            <img src="/img/coti/application.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>La app ya fue desarrollada</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {pageEndMovilAct ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>El costo estimado de tu app es de</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    {/* <center>
                        <button type="button" class="btn btn-link">
                            <img src="/img/coti/idea.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Es solo una idea</h3>
                        </button>
                    </center> */}
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <h1 style={{color: "#FFFFFF", fontSize: '550%'}}>${this.state.total}.99 Mxn</h1>
                        {/* <button type="button" class="btn btn-link">
                            <img src="/img/coti/sketch.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Ya se tiene el boceto</h3>
                        </button> */}
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    {/* <center>
                        <button type="button" class="btn btn-link">
                            <img src="/img/coti/code.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>La app esta en desarrollo</h3>
                        </button>
                    </center> */}
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page9Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Que tipo de diseño quiere que tenga su página web?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage9Sencilla}>
                            <img src="/img/coti/computer.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Sencilla</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage9Personalizada}>
                            <img src="/img/coti/computer (1).png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Personalizada, siguiendo los colores del logo</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage9NoSe}>
                            <img src="/img/coti/page63.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Aun no se</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page10Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Su página web ofrecerá productos ó servicios?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage10Si}>
                            <img src="/img/coti/new-product.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Si</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage10No}>
                            <img src="/img/coti/no-drinks.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage10NoSe}>
                            <img src="/img/coti/page63.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Aun no se</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page11Act ?
            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Su app necesita un panel administrativo?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage11Si}>
                            <img src="/img/coti/pagesix.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Si</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage11No}>
                            <img src="/img/coti/page62.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>No</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickPage11NoSe}>
                            <img src="/img/coti/page63.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Aun no se</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {page12Act ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>¿Cúal es el estado de su proyecto?</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndWeb}>
                            <img src="/img/coti/idea.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Es solo una idea</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndWeb}>
                            <img src="/img/coti/sketch.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Ya se tiene el boceto</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndWeb}>
                            <img src="/img/coti/data.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>La página ya esta en desarrollo</h3>
                        </button>
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-3">
                    <center>
                        <button type="button" class="btn btn-link" onClick={this.handleClickEndWeb}>
                            <img src="/img/coti/ui3.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Ya página ya fue desarrollada</h3>
                        </button>
                    </center>
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            {pageEndWebAct ?

            <div class="container">
            <div class="row">
                <div class="col">
                    <center>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <br/>
                        <h1 style={{color: 'aliceblue'}}>El costo estimado de si página web es de</h1>
                    </center>
                </div>
            </div>
            <br/>
            <div class="row pt-5">
                <div class="col-sm-6 col-md-4 col-lg-4">
                    {/* <center>
                        <button type="button" class="btn btn-link">
                            <img src="/img/coti/idea.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Es solo una idea</h3>
                        </button>
                    </center> */}
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    <center>
                        <h1 style={{color: "#FFFFFF", fontSize: '550%'}}>${this.state.total}.99 Mxn</h1>
                        {/* <button type="button" class="btn btn-link">
                            <img src="/img/coti/sketch.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>Ya se tiene el boceto</h3>
                        </button> */}
                    </center>
                </div>
                <div class="col-sm-6 col-md-4 col-lg-4">
                    {/* <center>
                        <button type="button" class="btn btn-link">
                            <img src="/img/coti/code.png" width="35%" alt=""/>
                            <br/>
                            <br/>
                            <h3 style={{color: 'aliceblue'}}>La app esta en desarrollo</h3>
                        </button>
                    </center> */}
                </div>
            </div>
            </div>

            :

            <div></div>

            }

            </div>
        )
    }
}

export default Navigation;